$backgroundColor: #ffffff;
$backgroundColor2: #f0fbff;
$backgroundColor3: #f7fcff;
$gray1: #737373;
$gray2: #e5e5e5;
$gray3: #c7d7e1;
$gray4: #dedede;
$gray5: #6d767e;
$blue1: #23a6f0;
$blue2: rgba(35, 166, 240, 0.2);
$black1: #252b42;
$black2: #22313f;
$white1: #fafafa;
$maxWidth: 1440;
$maxWidthContent: 1170;
$size-md: 767.98px;
$size-lg: 991.98px;
$size-xl: 1199.98px;
$size-xxl: 1439.98px;
