// html:not([data-scroll='0']) {
//   .header {
//     position: sticky;
//     top: 0;
//     z-index: 98;
//     background-color: white;
//     padding-top: 1.5rem !important;
//     padding-bottom: 1.5rem !important;
//     box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
//   }
// }

.header {
  box-sizing: border-box;
  background-color: $backgroundColor;
  transition: padding-top 0.25s, padding-bottom 0.25s, box-shadow 0.25s,
    background-color 0.25s;

  &__content {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: $maxWidthContent * 1px;
    padding: 18px 0;
    @media (max-width: $size-xxl) {
      max-width: calcSizing($maxWidthContent, $maxWidth, 1200) * 1px;
    }
    @media (max-width: $size-xl) {
      max-width: calcSizing($maxWidthContent, $maxWidth, 992) * 1px;
    }
    @media (max-width: $size-lg) {
      padding: 12px 16px;
    }
  }

  &__left {
    img {
      height: 6.4rem;
      width: 6rem;
      display: block;
      @media (max-width: $size-lg) {
        height: 4.8rem;
        width: 4.6rem;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: $size-lg) {
      display: none;
    }

    & > p {
      font-weight: 600;
      line-height: 2.4rem;
      text-align: center;
      letter-spacing: 0.02rem;
      color: $gray5;
      margin-left: 4.8rem;
      &:not(.hotline) {
        > a {
          all: inherit;
          margin-left: 0;
          color: $gray5;
        }
      }

      &.hotline {
        font-weight: bold;
        color: $blue1;
        & > p,
        a {
          color: inherit;
        }
      }
      &:hover {
        color: $blue1;
        cursor: pointer;
      }

      &.active {
        background-color: #23a6f0;
        mix-blend-mode: normal;
        border-radius: 50px;
        height: 44px;
        line-height: 44px;
        width: 160px;
        color: #ffffff;
        > a {
          all: inherit;
          margin-left: 0;
        }
      }
    }
  }

  &__menu {
    display: none;
    width: 3.2rem;

    @media (max-width: $size-lg) {
      display: block;
    }

    img {
      display: block;
    }

    & > div {
      height: 100vh;
      width: 26.4rem;
      position: fixed;
      overflow: hidden;
      top: 0;
      right: -100%;
      box-shadow: -2px 0px 2px rgba(0, 0, 0, 0.1);
      z-index: 999;
      background-color: #fff;
      transition: right 0.5s ease;
      & > img {
        width: 4.8rem;
        height: 4.8rem;
        margin-bottom: 1.2rem;
      }

      & > p {
        color: $black2;
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: 600;
        margin-bottom: 2.5rem;
        padding: 0 3rem;

        &.hotline {
          color: $blue1;
          & > p,
          a {
            color: inherit;
            display: inline;
            font-size: 1.6rem;
            line-height: 2.4rem;
            font-weight: 600;
          }
        }
      }
    }

    &-download {
      padding: 0 3rem;
      &-wrapper {
        padding: 1.2rem 2rem 0;
        border-top: 1px solid #a7adb2;
        text-align: center;
      }
      &-title {
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: $black2;
        margin-bottom: 1.2rem;
      }
      &-store {
        img {
          width: 16rem;
          height: auto;
          margin-bottom: 0.8rem;
        }
      }
      &-or {
        font-size: 1.4rem;
        line-height: 2.1rem;
        color: $gray5;
        padding-top: 0.4rem;
      }
      &-qr {
        img {
          width: 16rem;
          height: auto;
        }
      }
    }
  }
}
