.download {
  &__app {
    display: flex;
    position: relative;
    width: fit-content;
    overflow: hidden;
    width: 100%;
    & > picture {
      img {
        width: 100%;
      }
    }
  }
  &__qrCode {
    &--500 {
      display: none;
    }
    width: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    top: 50%;
    transform: translateY(-50%);
    & > h1 {
      font-weight: bold;
      font-size: 4rem;
      line-height: 5.9rem;
      margin-bottom: 2.4rem;
      color: #ffffff;
    }
    & > p {
      font-weight: 500;
      font-size: 2rem;
      line-height: 3.6rem;
      margin-bottom: 4.8rem;
      color: #ffffff;
    }
  }
  &__store {
    display: flex;
    img {
      cursor: pointer;
    }
    & > div:first-child {
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & > div:last-child {
      flex-direction: column;
      display: flex;
      & > img:first-child {
        margin-bottom: 2rem;
      }
    }
    &-line {
      width: 1px;
      height: 153px;
      background-color: #ffffff63;
      margin: 0 3.2rem;
    }
  }
}

@media screen and (max-width: 1199px) and (min-width: 1025px) {
  .download {
    &__qrCode {
      width: 55%;
      & > h1 {
        font-size: 4rem;
        line-height: 5.3rem;
        margin-bottom: 2rem;
      }
      & > p {
        font-size: 2rem;
        line-height: 3.3rem;
        margin-bottom: 4.3rem;
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 769px) {
  .download {
    &__qrCode {
      width: 70%;
      transform: translate(-50%, -50%);
      & > h1 {
        font-size: 4rem;
        line-height: 4.4rem;
      }
      & > p {
        font-size: 2rem;
        line-height: 3.6rem;
      }
    }
    &__store {
      width: 100%;
      & > div:first-child {
        width: fit-content;
        & > img {
          height: 100%;
        }
      }
      & > div:last-child {
        justify-content: space-between;
        & > img {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) and (min-width: 500px) {
  .download {
    &__qrCode {
      width: 75%;
      transform: translate(-50%, -50%);

      & > h1 {
        font-size: 4rem;
        line-height: 4.4rem;
      }
      & > p {
        font-size: 2rem;
        line-height: 3.6rem;
      }
    }
    &__store {
      width: 100%;
      & > div:first-child {
        width: fit-content;
        & > img {
          height: 100%;
        }
      }
      & > div:last-child {
        justify-content: space-between;
        & > img {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 499px) and (min-width: 321px) {
  .download {
    &__qrCode {
      width: calc(100% - 4.8rem);
      transform: translate(-50%, -50%);
      & > h1 {
        font-size: 3rem;
        line-height: 3.5rem;
      }
      & > p {
        font-size: 1.4rem;
        line-height: 1.8rem;
      }
    }
    &__store {
      width: 100%;
      & > div:first-child {
        width: fit-content;
        & > img {
          height: 11.2rem;
          width: 11.2rem;
        }
      }
      & > div:last-child {
        justify-content: space-between;
        & > img {
          width: 15rem;
        }
      }

      &-line {
        height: 11.2rem;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .download {
    &__qrCode {
      width: calc(100% - 4rem);
      transform: translate(-50%, -50%);
      & > h1 {
        font-size: 2.5rem;
        line-height: 2.9rem;
      }
      & > p {
        font-size: 1.4rem;
        line-height: 1.8rem;
        margin-bottom: 3rem;
      }
    }
    &__store {
      width: 100%;
      & > div:first-child {
        width: fit-content;
        & > img {
          height: 8rem;
          width: 8rem;
        }
      }
      & > div:last-child {
        justify-content: space-between;
        & > img {
          width: 10rem;
          &:first-child {
            margin-bottom: 1rem;
          }
        }
      }
      &-line {
        height: 8rem;
      }
    }
  }
}
