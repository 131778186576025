.section-feature {
  box-sizing: border-box;
  margin: auto;
  max-width: $maxWidthContent * 1px;
  margin-top: 82px;

  @media (max-width: $size-xxl) {
    max-width: calcSizing($maxWidthContent, $maxWidth, 1200) * 1px;
    margin-top: calcSizing(82, $maxWidth, 1200) * 1px;
  }
  @media (max-width: $size-xl) {
    max-width: calcSizing($maxWidthContent, $maxWidth, 992) * 1px;
    margin-top: calcSizing(82, $maxWidth, 992) * 1px;
  }
  @media (max-width: $size-lg) {
    max-width: calcSizing($maxWidthContent, $maxWidth, 768) * 1px;
    margin-top: calcSizing(82, $maxWidth, 768) * 1px;
  }
  @media (max-width: $size-md) {
    max-width: unset;
    padding: 20px 12px 28px;
    margin-top: 32px;
  }

  &__title {
    font-weight: 600;
    color: $black2;
    font-size: 40px;
    line-height: 60px;
    margin-bottom: 100px;
    position: relative;

    @media (max-width: $size-xxl) {
      font-size: calcSizing(40, $maxWidth, 1200) * 1px;
      line-height: calcSizing(60, $maxWidth, 1200) * 1px;
      margin-bottom: calcSizing(100, $maxWidth, 1200) * 1px;
    }
    @media (max-width: $size-xl) {
      font-size: calcSizing(40, $maxWidth, 992) * 1px;
      line-height: calcSizing(60, $maxWidth, 992) * 1px;
      margin-bottom: calcSizing(100, $maxWidth, 992) * 1px;
    }
    @media (max-width: $size-lg) {
      font-size: calcSizing(40, $maxWidth, 768) * 1px;
      line-height: calcSizing(60, $maxWidth, 768) * 1px;
      margin-bottom: calcSizing(100, $maxWidth, 768) * 1px;
    }
    @media (max-width: $size-md) {
      text-align: center;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 12px;
    }
  }

  &-content {
    &-wrapper {
      padding: 0 87px;
      @media (max-width: $size-xxl) {
        padding: 0 calcSizing(87, $maxWidth, 1200) * 1px;
      }
      @media (max-width: $size-xl) {
        padding: 0 calcSizing(87, $maxWidth, 992) * 1px;
      }
      @media (max-width: $size-lg) {
        padding: 0 calcSizing(87, $maxWidth, 768) * 1px;
      }
      @media (max-width: $size-md) {
        padding: 0;
      }
    }

    &-top {
      display: flex;
      @media (max-width: $size-md) {
        display: unset;
      }

      &__img {
        width: 40%;

        @media (max-width: $size-md) {
          width: 100%;
          margin-bottom: 24px;
          text-align: center;
        }
      }

      &__detail {
        flex: 1;
        padding-left: 88px;
        @media (max-width: $size-xxl) {
          padding-left: calcSizing(88, $maxWidth, 1200) * 1px;
        }
        @media (max-width: $size-xl) {
          padding-left: calcSizing(88, $maxWidth, 992) * 1px;
        }
        @media (max-width: $size-lg) {
          padding-left: calcSizing(88, $maxWidth, 768) * 1px;
        }
        @media (max-width: $size-md) {
          padding-left: 0;
        }

        .item {
          margin-bottom: 60px;
          padding-right: 140px;
          @media (max-width: $size-xxl) {
            margin-bottom: calcSizing(60, $maxWidth, 1200) * 1px;
            padding-right: calcSizing(140, $maxWidth, 1200) * 1px;
          }
          @media (max-width: $size-xl) {
            margin-bottom: calcSizing(60, $maxWidth, 1200) * 1px;
            padding-right: calcSizing(140, $maxWidth, 1200) * 1px;
          }
          @media (max-width: $size-lg) {
            margin-bottom: calcSizing(60, $maxWidth, 768) * 1px;
            padding-right: calcSizing(140, $maxWidth, 768) * 1px;
          }
          @media (max-width: $size-md) {
            margin-bottom: 24px;
            padding-right: unset;
          }

          &:nth-of-type(3) {
            margin-bottom: 0;
            @media (max-width: $size-md) {
              margin-bottom: 24px;
            }
          }

          &:nth-of-type(2) {
            padding-left: 129px;
            padding-right: unset;
            @media (max-width: $size-xxl) {
              padding-left: calcSizing(129, $maxWidth, 1200) * 1px;
            }
            @media (max-width: $size-xl) {
              padding-left: calcSizing(129, $maxWidth, 992) * 1px;
            }
            @media (max-width: $size-lg) {
              padding-left: calcSizing(129, $maxWidth, 768) * 1px;
            }
            @media (max-width: $size-md) {
              padding-left: 0;
            }
          }
        }
      }
    }

    &-bottom {
      padding: 64px 378px 0 161px;
      @media (max-width: $size-xxl) {
        padding: calcSizing(64, $maxWidth, 1200) * 1px
          calcSizing(378, $maxWidth, 1200) * 1px 0
          calcSizing(161, $maxWidth, 1200) * 1px;
      }
      @media (max-width: $size-xl) {
        padding: calcSizing(64, $maxWidth, 1200) * 1px
          calcSizing(378, $maxWidth, 1200) * 1px 0
          calcSizing(161, $maxWidth, 1200) * 1px;
      }
      @media (max-width: $size-lg) {
        padding: calcSizing(64, $maxWidth, 768) * 1px
          calcSizing(378, $maxWidth, 768) * 1px 0
          calcSizing(161, $maxWidth, 768) * 1px;
      }
      @media (max-width: $size-md) {
        padding: 0;
      }
    }

    &__icon {
      width: 60px;
      margin-bottom: 24px;
      @media (max-width: $size-xxl) {
        width: calcSizing(60, $maxWidth, 1200) * 1px;
        margin-bottom: calcSizing(24, $maxWidth, 1200) * 1px;
      }
      @media (max-width: $size-xl) {
        width: calcSizing(60, $maxWidth, 992) * 1px;
        margin-bottom: calcSizing(24, $maxWidth, 992) * 1px;
      }
      @media (max-width: $size-lg) {
        width: calcSizing(60, $maxWidth, 768) * 1px;
        margin-bottom: calcSizing(24, $maxWidth, 768) * 1px;
      }
      @media (max-width: $size-md) {
        width: 48px;
        margin-bottom: 12px;
      }
    }

    &__heading {
      font-weight: 600;
      color: $black2;
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 20px;

      @media (max-width: $size-xxl) {
        font-size: calcSizing(24, $maxWidth, 1200) * 1px;
        line-height: calcSizing(36, $maxWidth, 1200) * 1px;
        margin-bottom: calcSizing(20, $maxWidth, 1200) * 1px;
      }
      @media (max-width: $size-xl) {
        font-size: calcSizing(24, $maxWidth, 992) * 1px;
        line-height: calcSizing(36, $maxWidth, 992) * 1px;
        margin-bottom: calcSizing(20, $maxWidth, 992) * 1px;
      }
      @media (max-width: $size-lg) {
        font-size: calcSizing(24, $maxWidth, 768) * 1px;
        line-height: calcSizing(36, $maxWidth, 768) * 1px;
        margin-bottom: calcSizing(20, $maxWidth, 768) * 1px;
      }
      @media (max-width: $size-md) {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 12px;
      }
    }

    &__list {
      padding-left: 25px;
      @media (max-width: $size-md) {
        padding-left: 20px;
      }
      li {
        color: $gray5;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;

        @media (max-width: $size-xxl) {
          font-size: calcSizing(16, $maxWidth, 1200) * 1px;
          line-height: calcSizing(24, $maxWidth, 1200) * 1px;
          margin-bottom: calcSizing(8, $maxWidth, 1200) * 1px;
        }
        @media (max-width: $size-xl) {
          font-size: calcSizing(16, $maxWidth, 992) * 1px;
          line-height: calcSizing(24, $maxWidth, 992) * 1px;
          margin-bottom: calcSizing(8, $maxWidth, 992) * 1px;
        }
        @media (max-width: $size-lg) {
          font-size: calcSizing(16, $maxWidth, 768) * 1px;
          line-height: calcSizing(24, $maxWidth, 768) * 1px;
          margin-bottom: calcSizing(8, $maxWidth, 768) * 1px;
        }
        @media (max-width: $size-md) {
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 12px;
        }
      }
    }
  }
}
