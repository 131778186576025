.section-banner {
  &-desc {
    height: 856px;
    position: relative;

    @media (max-width: $size-xxl) {
      height: calcSizing(800, $maxWidth, 1200) * 1px;
    }
    @media (max-width: $size-xl) {
      height: calcSizing(800, $maxWidth, 992) * 1px;
    }
    @media (max-width: $size-lg) {
      height: calcSizing(800, $maxWidth, 768) * 1px;
    }
    @media (max-width: $size-md) {
      height: 223px;
    }

    &__bg {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &__content {
      position: absolute;
      top: 80px;
      left: 0;
      z-index: 2;

      @media (max-width: $size-xxl) {
        top: calcSizing(80, $maxWidth, 1200) * 1px;
      }
      @media (max-width: $size-xl) {
        top: calcSizing(80, $maxWidth, 992) * 1px;
      }
      @media (max-width: $size-lg) {
        top: calcSizing(80, $maxWidth, 768) * 1px;
      }
      @media (max-width: $size-md) {
        top: 6px;
        left: 16px;
      }

      &-bg {
        width: unset;
        width: 640px;

        @media (max-width: $size-xxl) {
          width: calcSizing(640, $maxWidth, 1200) * 1px;
        }
        @media (max-width: $size-xl) {
          width: calcSizing(640, $maxWidth, 992) * 1px;
        }
        @media (max-width: $size-lg) {
          width: calcSizing(640, $maxWidth, 768) * 1px;
        }
        @media (max-width: $size-md) {
          width: 172px;
        }

        img {
          width: 100%;
        }
      }

      &-info {
        position: absolute;
        z-index: 3;
        width: 100%;
        top: 0;
        left: 0;
        padding-right: 20px;
        padding-top: 140px;
        padding-left: 133px;
        box-sizing: border-box;

        @media (max-width: $size-xxl) {
          padding-right: calcSizing(20, $maxWidth, 1200) * 1px;
          padding-top: calcSizing(140, $maxWidth, 1200) * 1px;
          padding-left: calcSizing(133, $maxWidth, 1200) * 1px;
        }
        @media (max-width: $size-xl) {
          padding-right: calcSizing(20, $maxWidth, 992) * 1px;
          padding-top: calcSizing(140, $maxWidth, 992) * 1px;
          padding-left: calcSizing(133, $maxWidth, 992) * 1px;
        }
        @media (max-width: $size-lg) {
          padding-right: calcSizing(20, $maxWidth, 768) * 1px;
          padding-top: calcSizing(140, $maxWidth, 768) * 1px;
          padding-left: calcSizing(133, $maxWidth, 768) * 1px;
        }
        @media (max-width: $size-md) {
          padding-right: 12px;
          padding-top: 20px;
          padding-left: 20px;
        }

        h2,
        p {
          color: #fff;
        }

        h2 {
          font-style: normal;
          font-weight: 600;
          font-size: 40px;
          line-height: 60px;

          @media (max-width: $size-xxl) {
            font-size: calcSizing(40, $maxWidth, 1200) * 1px;
            line-height: calcSizing(60, $maxWidth, 1200) * 1px;
          }
          @media (max-width: $size-xl) {
            font-size: calcSizing(40, $maxWidth, 992) * 1px;
            line-height: calcSizing(60, $maxWidth, 992) * 1px;
          }
          @media (max-width: $size-lg) {
            font-size: calcSizing(40, $maxWidth, 768) * 1px;
            line-height: calcSizing(60, $maxWidth, 768) * 1px;
          }
          @media (max-width: $size-md) {
            line-height: 21px;
            font-size: 14px;
          }
        }

        p {
          font-weight: 400;
          font-size: 24px;
          line-height: 36px;
          margin-top: 24px;

          @media (max-width: $size-xxl) {
            font-size: calcSizing(24, $maxWidth, 1200) * 1px;
            line-height: calcSizing(36, $maxWidth, 1200) * 1px;
            margin-top: calcSizing(24, $maxWidth, 1200) * 1px;
          }
          @media (max-width: $size-xl) {
            font-size: calcSizing(24, $maxWidth, 992) * 1px;
            line-height: calcSizing(36, $maxWidth, 992) * 1px;
            margin-top: calcSizing(24, $maxWidth, 992) * 1px;
          }
          @media (max-width: $size-lg) {
            font-size: calcSizing(24, $maxWidth, 768) * 1px;
            line-height: calcSizing(36, $maxWidth, 768) * 1px;
            margin-top: calcSizing(24, $maxWidth, 768) * 1px;
          }
          @media (max-width: $size-md) {
            font-size: 12px;
            line-height: 18px;
            margin-top: 8px;
          }
        }

        a {
          display: block;
          background: #23a6f0;
          mix-blend-mode: normal;
          border-radius: 50px;
          outline: none;
          border: none;
          color: #fff;
          font-weight: 600;
          width: 200px;
          height: 50px;
          font-size: 16px;
          line-height: 24px;
          margin-top: 39px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          @media (max-width: $size-xxl) {
            width: calcSizing(200, $maxWidth, 1200) * 1px;
            height: calcSizing(50, $maxWidth, 1200) * 1px;
            font-size: calcSizing(16, $maxWidth, 1200) * 1px;
            line-height: calcSizing(24, $maxWidth, 1200) * 1px;
            margin-top: calcSizing(39, $maxWidth, 1200) * 1px;
          }
          @media (max-width: $size-xl) {
            width: calcSizing(200, $maxWidth, 1200) * 1px;
            height: calcSizing(50, $maxWidth, 1200) * 1px;
            font-size: calcSizing(16, $maxWidth, 992) * 1px;
            line-height: calcSizing(24, $maxWidth, 992) * 1px;
            margin-top: calcSizing(39, $maxWidth, 992) * 1px;
          }
          @media (max-width: $size-lg) {
            width: calcSizing(200, $maxWidth, 1200) * 1px;
            height: calcSizing(50, $maxWidth, 1200) * 1px;
            font-size: calcSizing(16, $maxWidth, 768) * 1px;
            line-height: calcSizing(24, $maxWidth, 768) * 1px;
            margin-top: calcSizing(39, $maxWidth, 768) * 1px;
          }
          @media (max-width: $size-md) {
            width: 120px;
            height: 40px;
            font-size: 14px;
            line-height: 21px;
            margin-top: 12px;
          }
        }
      }
    }
  }

  &-info {
    margin-top: 0;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 920px;

    @media (max-width: $size-xxl) {
      width: calcSizing(920, $maxWidth, 1200) * 1px;
    }
    @media (max-width: $size-xl) {
      width: calcSizing(920, $maxWidth, 992) * 1px;
    }
    @media (max-width: $size-lg) {
      width: calcSizing(920, $maxWidth, 768) * 1px;
    }
    @media (max-width: $size-md) {
      margin-top: 47px;
      padding: 0 23px;
      top: unset;
      left: unset;
      transform: unset;
      width: unset;
    }

    &__content {
      position: relative;
      z-index: 4;
      display: flex;
      background-color: #fff;
      box-shadow: 0px 4px 6px -2px rgba(17, 24, 39, 0.06),
        0px 10px 15px -3px rgba(17, 24, 39, 0.1);
      border-radius: 12px;
      padding: 40px;

      @media (max-width: $size-xxl) {
        padding: calcSizing(40, $maxWidth, 1200) * 1px;
        border-radius: calcSizing(12, $maxWidth, 1200) * 1px;
      }
      @media (max-width: $size-xl) {
        padding: calcSizing(40, $maxWidth, 992) * 1px;
        border-radius: calcSizing(12, $maxWidth, 992) * 1px;
      }
      @media (max-width: $size-lg) {
        padding: calcSizing(40, $maxWidth, 768) * 1px;
        border-radius: calcSizing(12, $maxWidth, 768) * 1px;
      }
      @media (max-width: $size-lg) {
        padding: 8px 8px 8px 11px;
        border-radius: 8px;
      }

      &-title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 600;
        color: $black2;
        font-size: 32px;
        line-height: 48px;

        @media (max-width: $size-xxl) {
          font-size: calcSizing(32, $maxWidth, 1200) * 1px;
          line-height: calcSizing(48, $maxWidth, 1200) * 1px;
        }
        @media (max-width: $size-xl) {
          font-size: calcSizing(32, $maxWidth, 992) * 1px;
          line-height: calcSizing(48, $maxWidth, 992) * 1px;
        }
        @media (max-width: $size-lg) {
          font-size: calcSizing(32, $maxWidth, 768) * 1px;
          line-height: calcSizing(48, $maxWidth, 768) * 1px;
        }
        @media (max-width: $size-md) {
          line-height: 21px;
          font-size: 14px;
        }

        img {
          display: block;
          width: 40px;
          margin-right: 12px;
          @media (max-width: $size-xxl) {
            margin-right: calcSizing(12, $maxWidth, 1200) * 1px;
          }
          @media (max-width: $size-xl) {
            margin-right: calcSizing(12, $maxWidth, 992) * 1px;
          }
          @media (max-width: $size-lg) {
            margin-right: calcSizing(12, $maxWidth, 768) * 1px;
          }

          @media (max-width: $size-md) {
            width: 20px;
            margin-right: 4px;
          }
        }
      }

      &-desc {
        color: $gray5;
        font-size: 18px;
        line-height: 27px;
        margin-top: 12px;

        @media (max-width: $size-xxl) {
          font-size: calcSizing(18, $maxWidth, 1200) * 1px;
          line-height: calcSizing(27, $maxWidth, 1200) * 1px;
          margin-top: calcSizing(12, $maxWidth, 1200) * 1px;
        }
        @media (max-width: $size-xl) {
          font-size: calcSizing(18, $maxWidth, 992) * 1px;
          line-height: calcSizing(27, $maxWidth, 992) * 1px;
          margin-top: calcSizing(12, $maxWidth, 992) * 1px;
        }
        @media (max-width: $size-lg) {
          font-size: calcSizing(18, $maxWidth, 768) * 1px;
          line-height: calcSizing(27, $maxWidth, 768) * 1px;
          margin-top: calcSizing(12, $maxWidth, 768) * 1px;
        }
        @media (max-width: $size-md) {
          font-size: 12px;
          line-height: 18px;
          margin-top: 4px;
        }
      }

      .item {
        width: 33.3333%;
        text-align: center;
        box-sizing: border-box;

        &:nth-child(2) {
          padding: 0 10px;
        }
      }
    }
  }
}
