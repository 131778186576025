.section-download {
  position: relative;

  &-bg {
    img {
      width: 100%;
    }
  }

  &-content {
    position: absolute;
    width: 100%;
    max-width: $maxWidthContent * 1px;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    top: 228px;

    @media (max-width: $size-xxl) {
      top: calcSizing(228, $maxWidth, 1200) * 1px;
      max-width: calcSizing($maxWidthContent, $maxWidth, 1200) * 1px;
    }
    @media (max-width: $size-xl) {
      top: calcSizing(228, $maxWidth, 992) * 1px;
      max-width: calcSizing($maxWidthContent, $maxWidth, 992) * 1px;
    }
    @media (max-width: $size-lg) {
      top: calcSizing(228, $maxWidth, 768) * 1px;
      max-width: calcSizing($maxWidthContent, $maxWidth, 768) * 1px;
    }
    @media (max-width: $size-md) {
      top: 0;
      padding: 24px 16px 0;
    }
  }

  &__title {
    font-weight: 600;
    color: #fff;
    font-size: 40px;
    line-height: 60px;
    margin-bottom: 20px;

    @media (max-width: $size-xxl) {
      font-size: calcSizing(40, $maxWidth, 1200) * 1px;
      line-height: calcSizing(60, $maxWidth, 1200) * 1px;
      margin-bottom: calcSizing(20, $maxWidth, 1200) * 1px;
    }
    @media (max-width: $size-xl) {
      font-size: calcSizing(40, $maxWidth, 992) * 1px;
      line-height: calcSizing(60, $maxWidth, 992) * 1px;
      margin-bottom: calcSizing(20, $maxWidth, 992) * 1px;
    }
    @media (max-width: $size-lg) {
      font-size: calcSizing(40, $maxWidth, 768) * 1px;
      line-height: calcSizing(60, $maxWidth, 768) * 1px;
      margin-bottom: calcSizing(20, $maxWidth, 768) * 1px;
    }
    @media (max-width: $size-md) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 12px;
    }
  }

  &__desc {
    color: #fff;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 24px;
    max-width: 628px;

    @media (max-width: $size-xxl) {
      font-size: calcSizing(24, $maxWidth, 1200) * 1px;
      line-height: calcSizing(36, $maxWidth, 1200) * 1px;
      margin-bottom: calcSizing(40, $maxWidth, 1200) * 1px;
      max-width: calcSizing(628, $maxWidth, 1200) * 1px;
    }
    @media (max-width: $size-xl) {
      font-size: calcSizing(24, $maxWidth, 992) * 1px;
      line-height: calcSizing(36, $maxWidth, 992) * 1px;
      margin-bottom: calcSizing(40, $maxWidth, 992) * 1px;
      max-width: calcSizing(628, $maxWidth, 992) * 1px;
    }
    @media (max-width: $size-lg) {
      font-size: calcSizing(24, $maxWidth, 768) * 1px;
      line-height: calcSizing(36, $maxWidth, 768) * 1px;
      margin-bottom: calcSizing(40, $maxWidth, 768) * 1px;
      max-width: calcSizing(628, $maxWidth, 768) * 1px;
    }
    @media (max-width: $size-md) {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 12px;
      max-width: unset;
    }
  }

  &__img {
    display: flex;
    align-items: center;

    &-store {
      width: 200px;
      @media (max-width: $size-xxl) {
        width: calcSizing(200, $maxWidth, 1200) * 1px;
      }
      @media (max-width: $size-xl) {
        width: calcSizing(200, $maxWidth, 992) * 1px;
      }
      @media (max-width: $size-lg) {
        width: calcSizing(200, $maxWidth, 768) * 1px;
      }
      @media (max-width: $size-md) {
        width: 120px;
      }
    }

    &-qr {
      margin-left: 60px;
      width: 152px;
      @media (max-width: $size-xxl) {
        margin-left: calcSizing(60, $maxWidth, 1200) * 1px;
        width: calcSizing(152, $maxWidth, 1200) * 1px;
      }
      @media (max-width: $size-xl) {
        margin-left: calcSizing(60, $maxWidth, 992) * 1px;
        width: calcSizing(152, $maxWidth, 992) * 1px;
      }
      @media (max-width: $size-lg) {
        margin-left: calcSizing(60, $maxWidth, 768) * 1px;
        width: calcSizing(152, $maxWidth, 768) * 1px;
      }
      @media (max-width: $size-md) {
        margin-left: 12px;
        width: 86px;
      }
    }

    img {
      width: 100%;
    }
  }
}
