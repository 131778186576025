:root {
  font-size: 10px;
  background-color: $backgroundColor;
  scroll-behavior: smooth;
  font-family: "Be Vietnam Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a,
ul,
li,
p,
h1, h2, h3 ,h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  line-height: 2.4rem;
  letter-spacing: 0.02rem;
  color: $black1;
}

a {
  text-decoration: none;
}

@mixin a {
  width: 100vw;
  margin: 0 auto;
  background-color: $backgroundColor3;
}
.container {
 @include a;
}
