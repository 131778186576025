.supports {
  padding-right: 10rem;
  display: flex;

  &-wrapper {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: relative;
    background-color: #fff;

    .btn-sidebar {
      &-open {
        position: absolute;
        top: 6px;
        left: 5px;
      }
      &-close {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e8e8e8;
        padding-bottom: 5px;
        img {
          width: 6rem;
        }
      }
    }
  }

  &-content {
    padding-left: 20px;
    padding-bottom: 10rem;
    flex: 1;
  }

  &__title {
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 1rem;
    padding-top: 2rem;
    @media (max-width: $size-xxl) {
      font-size: calcSizing(28, $maxWidth, 1200) * 1px;
      line-height: calcSizing(40, $maxWidth, 1200) * 1px;
    }
    @media (max-width: $size-xl) {
      font-size: calcSizing(28, $maxWidth, 992) * 1px;
      line-height: calcSizing(40, $maxWidth, 992) * 1px;
    }
    @media (max-width: $size-lg) {
      font-size: calcSizing(28, $maxWidth, 768) * 1px;
      line-height: calcSizing(40, $maxWidth, 768) * 1px;
    }
    @media (max-width: $size-md) {
      font-size: 20px;
      line-height: 22px;
      margin-bottom: 15px;
    }
  }

  &__heading {
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-size: 24px;
    line-height: 36px;

    @media (max-width: $size-xxl) {
      font-size: calcSizing(24, $maxWidth, 1200) * 1px;
      line-height: calcSizing(36, $maxWidth, 1200) * 1px;
    }
    @media (max-width: $size-xl) {
      font-size: calcSizing(24, $maxWidth, 992) * 1px;
      line-height: calcSizing(36, $maxWidth, 992) * 1px;
    }
    @media (max-width: $size-lg) {
      font-size: calcSizing(24, $maxWidth, 768) * 1px;
      line-height: calcSizing(36, $maxWidth, 768) * 1px;
    }
    @media (max-width: $size-md) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__img {
    display: flex;
    flex-wrap: wrap;
    text-align: center;

    figure {
      margin-bottom: 30px;
    }

    img {
      width: 60%;
    }
  }

  &__warning {
    margin-top: 1rem;
  }

  p {
    color: $gray5;
    font-size: 16px;
    line-height: 24px;

    @media (max-width: $size-xxl) {
      font-size: calcSizing(16, $maxWidth, 1200) * 1px;
      line-height: calcSizing(24, $maxWidth, 1200) * 1px;
    }
    @media (max-width: $size-xl) {
      font-size: calcSizing(16, $maxWidth, 992) * 1px;
      line-height: calcSizing(24, $maxWidth, 992) * 1px;
    }
    @media (max-width: $size-lg) {
      font-size: calcSizing(16, $maxWidth, 768) * 1px;
      line-height: calcSizing(24, $maxWidth, 768) * 1px;
    }
    @media (max-width: $size-md) {
      font-size: 14px;
      line-height: 21px;
    }
  }

  p,
  blockquote {
    strong {
      color: $blue1;
    }
  }

  ol {
    list-style: none;
    margin-bottom: 2rem;
    li {
      padding-left: 1.5rem;
      list-style-type: none;
      color: $gray5;
      font-size: 14px;
      line-height: 21px;
      &:before {
        content: "\2022";
        margin-left: -1.5rem;
        margin-right: 0.3rem;
        text-align: right;
      }
    }
  }

  blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
  }
}

.supports-category {
  width: 250px;
  padding: 10px;
  box-sizing: border-box;
  border-right: 1px solid #e8e8e8;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) and (min-width: 500px) {
  .supports {
    flex-direction: column;
    padding-left: 10rem;
    &-category {
      position: fixed;
      height: 100vh;
      overflow: hidden;
      background-color: #fff;
      left: -100%;
      border-right: unset;
      transition: all 0.3s;
      width: 100vw;
      top: 0;

      &.active {
        left: 0;
        overflow-y: scroll;
        transition: all 0.3s;
      }
    }
    &-content {
      padding-left: 0;
    }

    &__img {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      img {
        width: 80%;
      }
    }
  }
}

@media screen and (max-width: 499px) and (min-width: 321px) {
  .supports {
    padding: 0 1rem;
    min-width: unset;
    flex-direction: column;
    padding-top: 30px;

    &-category {
      position: fixed;
      height: 100vh;
      overflow: hidden;
      background-color: #fff;
      left: -100%;
      border-right: unset;
      transition: all 0.3s;
      width: 100vw;
      top: 0;

      &.active {
        left: 0;
        overflow-y: scroll;
        transition: all 0.3s;
      }
    }

    &-content {
      padding-left: 0;
      padding-bottom: 1rem;
    }

    &__img {
      figure {
        margin: 0;
        margin-bottom: 30px;
      }
      img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .supports {
    padding: 0 1rem 1rem;
    min-width: unset;
    flex-direction: column;

    &-category {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      border-right: unset;
      border-bottom: 1px solid #e8e8e8;
    }

    &-content {
      padding-left: 0;
      padding-bottom: 1rem;
    }

    &__img {
      figure {
        margin: 0;
      }
      img {
        width: 100%;
      }
    }
  }
}
