.section-container {
  @media (max-width: $size-md) {
    padding: 0 16px;
  }
}

.section-feedback {
  box-sizing: border-box;
  margin: auto;
  background-color: #fff;
  box-shadow: 0px 4px 6px -2px rgba(17, 24, 39, 0.06),
    0px 10px 15px -3px rgba(17, 24, 39, 0.1);
  border-radius: 12px;
  max-width: $maxWidthContent * 1px;
  margin-top: 78px;
  padding: 70px 100px 86px;

  @media (max-width: $size-xxl) {
    max-width: calcSizing($maxWidthContent, $maxWidth, 1200) * 1px;
    margin-top: calcSizing(78, $maxWidth, 1200) * 1px;
    padding: calcSizing(70, $maxWidth, 1200) * 1px
      calcSizing(100, $maxWidth, 1200) * 1px calcSizing(86, $maxWidth, 1200) *
      1px;
  }
  @media (max-width: $size-xl) {
    max-width: calcSizing($maxWidthContent, $maxWidth, 992) * 1px;
    margin-top: calcSizing(78, $maxWidth, 992) * 1px;
    padding: calcSizing(70, $maxWidth, 992) * 1px
      calcSizing(100, $maxWidth, 992) * 1px calcSizing(86, $maxWidth, 992) * 1px;
  }
  @media (max-width: $size-lg) {
    max-width: calcSizing($maxWidthContent, $maxWidth, 768) * 1px;
    margin-top: calcSizing(78, $maxWidth, 768) * 1px;
    padding: calcSizing(70, $maxWidth, 768) * 1px
      calcSizing(100, $maxWidth, 768) * 1px calcSizing(86, $maxWidth, 768) * 1px;
  }
  @media (max-width: $size-md) {
    max-width: unset;
    padding: 20px 6px 28px;
    margin-top: 32px;
  }

  &-title {
    font-weight: 600;
    color: $black2;
    text-align: center;
    font-size: 40px;
    line-height: 60px;
    margin-bottom: 60px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #23a6f0;
      border-radius: 7px;
      width: 80px;
      height: 8px;
    }

    @media (max-width: $size-xxl) {
      font-size: calcSizing(40, $maxWidth, 1200) * 1px;
      line-height: calcSizing(60, $maxWidth, 1200) * 1px;
      margin-bottom: calcSizing(60, $maxWidth, 1200) * 1px;

      &::before {
        width: calcSizing(80, $maxWidth, 1200) * 1px;
        height: calcSizing(8, $maxWidth, 1200) * 1px;
        top: calcSizing(-20, $maxWidth, 1200) * 1px;
      }
    }
    @media (max-width: $size-xl) {
      font-size: calcSizing(40, $maxWidth, 992) * 1px;
      line-height: calcSizing(60, $maxWidth, 992) * 1px;
      margin-bottom: calcSizing(60, $maxWidth, 992) * 1px;

      &::before {
        width: calcSizing(80, $maxWidth, 992) * 1px;
        height: calcSizing(8, $maxWidth, 992) * 1px;
        top: calcSizing(-20, $maxWidth, 992) * 1px;
      }
    }
    @media (max-width: $size-lg) {
      font-size: calcSizing(40, $maxWidth, 768) * 1px;
      line-height: calcSizing(60, $maxWidth, 768) * 1px;
      margin-bottom: calcSizing(60, $maxWidth, 768) * 1px;

      &::before {
        width: calcSizing(80, $maxWidth, 768) * 1px;
        height: calcSizing(8, $maxWidth, 768) * 1px;
        top: calcSizing(-20, $maxWidth, 768) * 1px;
      }
    }
    @media (max-width: $size-md) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;

      &::before {
        top: -4px;
        width: 40px;
        height: 4px;
      }
    }
  }

  &-slider {
    .item-slider {
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      padding: 40px 22px 0;

      @media (max-width: $size-xxl) {
        padding: calcSizing(40, $maxWidth, 1200) * 1px
          calcSizing(22, $maxWidth, 1200) * 1px 0;
      }
      @media (max-width: $size-xl) {
        padding: calcSizing(40, $maxWidth, 1200) * 1px
          calcSizing(22, $maxWidth, 992) * 1px 0;
      }
      @media (max-width: $size-lg) {
        padding: calcSizing(40, $maxWidth, 1200) * 1px
          calcSizing(22, $maxWidth, 768) * 1px 0;
      }
      @media (max-width: $size-md) {
        padding: 0 6px;
      }
    }

    &__img {
      box-sizing: border-box;
      width: 50%;
      padding-right: 20px;

      @media (max-width: $size-xxl) {
        padding-right: calcSizing(20, $maxWidth, 1200) * 1px;
      }
      @media (max-width: $size-xl) {
        padding-right: calcSizing(20, $maxWidth, 992) * 1px;
      }
      @media (max-width: $size-lg) {
        padding-right: calcSizing(20, $maxWidth, 768) * 1px;
      }

      @media (max-width: $size-md) {
        width: 100%;
        padding-right: unset;
        margin-bottom: 12px;
      }
    }
    &__text {
      box-sizing: border-box;
      width: 50%;
      position: relative;
      padding-left: 20px;
      padding-top: 24px;

      @media (max-width: $size-xxl) {
        padding-left: calcSizing(20, $maxWidth, 1200) * 1px;
        padding-top: calcSizing(24, $maxWidth, 1200) * 1px;
      }
      @media (max-width: $size-xl) {
        padding-left: calcSizing(20, $maxWidth, 992) * 1px;
        padding-top: calcSizing(24, $maxWidth, 992) * 1px;
      }
      @media (max-width: $size-lg) {
        padding-left: calcSizing(20, $maxWidth, 768) * 1px;
        padding-top: calcSizing(24, $maxWidth, 768) * 1px;
      }

      @media (max-width: $size-md) {
        width: 100%;
        padding-left: unset;
        padding-top: 40px;
      }

      &-comma {
        position: absolute;
        top: -40px;
        width: 40px;

        @media (max-width: $size-xxl) {
          width: calcSizing(40, $maxWidth, 1200) * 1px;
          top: calcSizing(-40, $maxWidth, 1200) * 1px;
        }
        @media (max-width: $size-xl) {
          width: calcSizing(40, $maxWidth, 992) * 1px;
          top: calcSizing(-40, $maxWidth, 992) * 1px;
        }
        @media (max-width: $size-lg) {
          width: calcSizing(40, $maxWidth, 768) * 1px;
          top: calcSizing(-40, $maxWidth, 768) * 1px;
        }
        @media (max-width: $size-md) {
          width: 32px;
          top: 0;
        }
      }

      &-content {
        color: $gray5;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 24px;
        text-align: justify;

        @media (max-width: $size-xxl) {
          font-size: calcSizing(18, $maxWidth, 1200) * 1px;
          line-height: calcSizing(27, $maxWidth, 1200) * 1px;
          margin-bottom: calcSizing(24, $maxWidth, 1200) * 1px;
        }
        @media (max-width: $size-xl) {
          font-size: calcSizing(18, $maxWidth, 992) * 1px;
          line-height: calcSizing(27, $maxWidth, 992) * 1px;
          margin-bottom: calcSizing(24, $maxWidth, 992) * 1px;
        }
        @media (max-width: $size-lg) {
          font-size: calcSizing(18, $maxWidth, 768) * 1px;
          line-height: calcSizing(27, $maxWidth, 768) * 1px;
          margin-bottom: calcSizing(24, $maxWidth, 768) * 1px;
        }
        @media (max-width: $size-md) {
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 12px;
        }
      }

      &-star {
        display: flex;
        margin-bottom: 16px;

        img {
          width: 19px;
          margin-right: 7px;
        }

        @media (max-width: $size-xxl) {
          margin-bottom: calcSizing(16, $maxWidth, 1200) * 1px;

          img {
            width: calcSizing(19, $maxWidth, 1200) * 1px;
            margin-right: calcSizing(7, $maxWidth, 1200) * 1px;
          }
        }
        @media (max-width: $size-xl) {
          margin-bottom: calcSizing(16, $maxWidth, 992) * 1px;

          img {
            width: calcSizing(19, $maxWidth, 992) * 1px;
            margin-right: calcSizing(7, $maxWidth, 992) * 1px;
          }
        }
        @media (max-width: $size-lg) {
          margin-bottom: calcSizing(16, $maxWidth, 768) * 1px;

          img {
            width: calcSizing(19, $maxWidth, 768) * 1px;
            margin-right: calcSizing(7, $maxWidth, 768) * 1px;
          }
        }
        @media (max-width: $size-md) {
          margin-bottom: 8px;

          img {
            width: 14px;
            margin-right: 7px;
          }
        }
      }

      &-name {
        font-weight: 600;
        color: $black2;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 8px;

        @media (max-width: $size-xxl) {
          font-size: calcSizing(18, $maxWidth, 1200) * 1px;
          line-height: calcSizing(27, $maxWidth, 1200) * 1px;
          margin-bottom: calcSizing(8, $maxWidth, 1200) * 1px;
        }
        @media (max-width: $size-xl) {
          font-size: calcSizing(18, $maxWidth, 992) * 1px;
          line-height: calcSizing(27, $maxWidth, 992) * 1px;
          margin-bottom: calcSizing(8, $maxWidth, 992) * 1px;
        }
        @media (max-width: $size-lg) {
          font-size: calcSizing(18, $maxWidth, 768) * 1px;
          line-height: calcSizing(27, $maxWidth, 768) * 1px;
          margin-bottom: calcSizing(8, $maxWidth, 768) * 1px;
        }
        @media (max-width: $size-md) {
          font-size: 14px;
          line-height: 21px;
        }
      }

      &-job {
        color: $gray5;
        font-size: 14px;
        line-height: 21px;

        @media (max-width: $size-xxl) {
          font-size: calcSizing(14, $maxWidth, 1200) * 1px;
          line-height: calcSizing(21, $maxWidth, 1200) * 1px;
        }
        @media (max-width: $size-xl) {
          font-size: calcSizing(14, $maxWidth, 992) * 1px;
          line-height: calcSizing(21, $maxWidth, 992) * 1px;
        }
        @media (max-width: $size-lg) {
          font-size: calcSizing(14, $maxWidth, 768) * 1px;
          line-height: calcSizing(21, $maxWidth, 768) * 1px;
        }
        @media (max-width: $size-md) {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }

  .slick-prev,
  .slick-next {
    width: unset;
    height: unset;
    &:before {
      content: "";
    }

    img {
      width: 40px;
      @media (max-width: $size-xxl) {
        width: calcSizing(40, $maxWidth, 1200) * 1px;
      }
      @media (max-width: $size-xl) {
        width: calcSizing(40, $maxWidth, 992) * 1px;
      }
      @media (max-width: $size-lg) {
        width: calcSizing(40, $maxWidth, 768) * 1px;
      }
      @media (max-width: $size-md) {
        display: none;
      }
    }
  }

  .slick-prev {
    left: -60px;
    @media (max-width: $size-xxl) {
      left: calcSizing(-60, $maxWidth, 1200) * 1px;
    }
    @media (max-width: $size-xl) {
      left: calcSizing(-60, $maxWidth, 992) * 1px;
    }
    @media (max-width: $size-lg) {
      left: calcSizing(-60, $maxWidth, 768) * 1px;
    }
    @media (max-width: $size-md) {
      left: unset;
    }
  }

  .slick-next {
    right: -60px;
    @media (max-width: $size-xxl) {
      right: calcSizing(-60, $maxWidth, 1200) * 1px;
    }
    @media (max-width: $size-xl) {
      right: calcSizing(-60, $maxWidth, 992) * 1px;
    }
    @media (max-width: $size-lg) {
      right: calcSizing(-60, $maxWidth, 768) * 1px;
    }
    @media (max-width: $size-md) {
      right: unset;
    }
  }

  .slick-dots {
    bottom: -48px;
    @media (max-width: $size-xxl) {
      bottom: calcSizing(-48, $maxWidth, 1200) * 1px;
    }
    @media (max-width: $size-xl) {
      bottom: calcSizing(-48, $maxWidth, 992) * 1px;
    }
    @media (max-width: $size-lg) {
      bottom: calcSizing(-48, $maxWidth, 768) * 1px;
    }
    @media (max-width: $size-md) {
      bottom: -16px;
    }

    li {
      width: unset;
      height: unset;
      margin: 0 6px;

      @media (max-width: $size-md) {
        margin: 0 4px;
      }

      &.slick-active {
        button {
          background-color: #23a6f0;
          width: 40px;
          height: 8px;
          border-radius: 4px;

          @media (max-width: $size-md) {
            width: 24px;
            height: 6px;
            background: #23a6f0;
          }
        }
      }

      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $gray5;

        &::before {
          content: "";
          width: unset;
          height: unset;
        }

        @media (max-width: $size-md) {
          width: 6px;
          height: 6px;
        }
      }
    }
  }
}
