// common
@import "./variables.scss";
@import "./global.scss";
// functions
@import "./functions/sizing";
// layout
@import "./layout/header.scss";
@import "./layout/main.scss";
@import "./layout/footer.scss";
@import "./layout/privacy.scss";
@import "./layout/download.scss";
@import "./layout/supports.scss";
// component
@import "./component/helper.scss";
@import "./component/breadcrumbs.scss";
@import "./component/section-banner";
@import "./component/section-about";
@import "./component/section-feedback";
@import "./component/section-feature";
@import "./component/section-download";
// responsive
@import "./responsive.scss";
// library
@import "react-slideshow-image/dist/styles.css";
@import '~slick-carousel/slick/slick.scss';
@import '~slick-carousel/slick/slick-theme.scss';