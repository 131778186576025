.section-about {
  max-width: $maxWidthContent * 1px;
  margin: auto;
  margin-top: 0;
  box-sizing: border-box;
  padding: 0 42px;

  @media (max-width: $size-xxl) {
    max-width: calcSizing($maxWidthContent, $maxWidth, 1200) * 1px;
    padding: 0 calcSizing(42, $maxWidth, 1200) * 1px;
  }
  @media (max-width: $size-xl) {
    max-width: calcSizing($maxWidthContent, $maxWidth, 992) * 1px;
    padding: 0 calcSizing(42, $maxWidth, 992) * 1px;
  }
  @media (max-width: $size-lg) {
    max-width: calcSizing($maxWidthContent, $maxWidth, 768) * 1px;
    padding: 0 calcSizing(42, $maxWidth, 768) * 1px;
  }
  @media (max-width: $size-md) {
    max-width: unset;
    padding: 32px 16px 0;
  }

  &-title {
    font-weight: 600;
    color: $black2;
    text-align: center;
    font-size: 40px;
    line-height: 60px;
    margin-bottom: 60px;

    @media (max-width: $size-xxl) {
      font-size: calcSizing(40, $maxWidth, 1200) * 1px;
      line-height: calcSizing(60, $maxWidth, 1200) * 1px;
      margin-bottom: calcSizing(60, $maxWidth, 1200) * 1px;
    }
    @media (max-width: $size-xl) {
      font-size: calcSizing(40, $maxWidth, 992) * 1px;
      line-height: calcSizing(60, $maxWidth, 992) * 1px;
      margin-bottom: calcSizing(60, $maxWidth, 992) * 1px;
    }
    @media (max-width: $size-lg) {
      font-size: calcSizing(40, $maxWidth, 768) * 1px;
      line-height: calcSizing(60, $maxWidth, 768) * 1px;
      margin-bottom: calcSizing(60, $maxWidth, 768) * 1px;
    }
    @media (max-width: $size-md) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 88px;
    &:nth-of-type(odd) {
      .section-about-content__img {
        order: 1;
        padding-left: 22px;
        @media (max-width: $size-xxl) {
          padding-left: calcSizing(22, $maxWidth, 1200) * 1px;
        }
        @media (max-width: $size-xl) {
          padding-left: calcSizing(22, $maxWidth, 992) * 1px;
        }
        @media (max-width: $size-lg) {
          padding-left: calcSizing(22, $maxWidth, 768) * 1px;
        }
        @media (max-width: $size-md) {
          order: unset;
          padding-left: unset;
        }
      }
      .section-about-content__text {
        padding-right: 113px;
        @media (max-width: $size-xxl) {
          padding-right: calcSizing(113, $maxWidth, 1200) * 1px;
        }
        @media (max-width: $size-xl) {
          padding-right: calcSizing(113, $maxWidth, 992) * 1px;
        }
        @media (max-width: $size-lg) {
          padding-right: calcSizing(113, $maxWidth, 768) * 1px;
        }
        @media (max-width: $size-md) {
          padding-right: unset;
        }
      }
    }

    &:nth-of-type(even) {
      .section-about-content__img {
        padding-right: 22px;
        @media (max-width: $size-xxl) {
          padding-right: calcSizing(22, $maxWidth, 1200) * 1px;
        }
        @media (max-width: $size-xl) {
          padding-right: calcSizing(22, $maxWidth, 992) * 1px;
        }
        @media (max-width: $size-lg) {
          padding-right: calcSizing(22, $maxWidth, 768) * 1px;
        }
        @media (max-width: $size-md) {
          padding-right: unset;
        }
      }
      .section-about-content__text {
        padding-left: 113px;
        @media (max-width: $size-xxl) {
          padding-left: calcSizing(113, $maxWidth, 1200) * 1px;
        }
        @media (max-width: $size-xl) {
          padding-left: calcSizing(113, $maxWidth, 992) * 1px;
        }
        @media (max-width: $size-lg) {
          padding-left: calcSizing(113, $maxWidth, 768) * 1px;
        }
        @media (max-width: $size-md) {
          padding-left: unset;
        }
      }
    }

    @media (max-width: $size-xxl) {
      margin-bottom: calcSizing(88, $maxWidth, 1200) * 1px;
    }
    @media (max-width: $size-xl) {
      margin-bottom: calcSizing(88, $maxWidth, 992) * 1px;
    }
    @media (max-width: $size-lg) {
      margin-bottom: calcSizing(88, $maxWidth, 768) * 1px;
    }
    @media (max-width: $size-md) {
      margin-bottom: 24px;
    }

    &__img {
      width: 50%;
      box-sizing: border-box;
      @media (max-width: $size-md) {
        width: 100%;
      }
    }

    &__text {
      width: 50%;
      box-sizing: border-box;

      @media (max-width: $size-md) {
        width: 100%;
      }

      &-title {
        font-weight: 600;
        color: $black2;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 24px;
        margin-top: 92px;

        @media (max-width: $size-xxl) {
          font-size: calcSizing(24, $maxWidth, 1200) * 1px;
          line-height: calcSizing(36, $maxWidth, 1200) * 1px;
          margin-bottom: calcSizing(24, $maxWidth, 1200) * 1px;
          margin-top: calcSizing(92, $maxWidth, 1200) * 1px;
        }
        @media (max-width: $size-xl) {
          font-size: calcSizing(24, $maxWidth, 992) * 1px;
          line-height: calcSizing(36, $maxWidth, 992) * 1px;
          margin-bottom: calcSizing(24, $maxWidth, 992) * 1px;
          margin-top: calcSizing(92, $maxWidth, 992) * 1px;
        }
        @media (max-width: $size-lg) {
          font-size: calcSizing(24, $maxWidth, 768) * 1px;
          line-height: calcSizing(36, $maxWidth, 768) * 1px;
          margin-bottom: calcSizing(24, $maxWidth, 768) * 1px;
          margin-top: calcSizing(92, $maxWidth, 768) * 1px;
        }
        @media (max-width: $size-md) {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 12px;
          margin-top: 24px;
        }
      }

      &-desc {
        padding-left: 25px;
        @media (max-width: $size-md) {
          padding-left: 20px;
        }

        li {
          color: $gray5;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 12px;

          @media (max-width: $size-xxl) {
            font-size: calcSizing(16, $maxWidth, 1200) * 1px;
            line-height: calcSizing(24, $maxWidth, 1200) * 1px;
            margin-bottom: calcSizing(12, $maxWidth, 1200) * 1px;
          }
          @media (max-width: $size-xl) {
            font-size: calcSizing(16, $maxWidth, 992) * 1px;
            line-height: calcSizing(24, $maxWidth, 992) * 1px;
            margin-bottom: calcSizing(12, $maxWidth, 992) * 1px;
          }
          @media (max-width: $size-lg) {
            font-size: calcSizing(16, $maxWidth, 768) * 1px;
            line-height: calcSizing(24, $maxWidth, 768) * 1px;
            margin-bottom: calcSizing(12, $maxWidth, 768) * 1px;
          }
          @media (max-width: $size-md) {
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}
