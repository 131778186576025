.privacy {
  background-color: #fff;
  padding: 2rem 0;

  &-wrapper {
    max-width: $maxWidthContent * 1px;
    margin: 0 auto;
    box-sizing: border-box;

    @media (max-width: $size-xxl) {
      max-width: calcSizing($maxWidthContent, $maxWidth, 1200) * 1px;
    }
    @media (max-width: $size-xl) {
      max-width: calcSizing($maxWidthContent, $maxWidth, 992) * 1px;
    }
    @media (max-width: $size-lg) {
      max-width: calcSizing($maxWidthContent, $maxWidth, 768) * 1px;
    }
    @media (max-width: $size-md) {
      max-width: 100%;
      padding: 0 16px;
    }
  }

  a {
    text-decoration: underline;
    font-size: 1.7rem;
    line-height: 2.7rem;
    color: $blue1;
  }

  h1 {
    font-size: 2.8rem;
    line-height: 4rem;
    margin-bottom: 1rem;
  }
  h3 {
    margin-top: 2rem;
    font-size: 2rem;
    line-height: 3.2rem;
  }
  p {
    font-size: 1.7rem;
    line-height: 2.7rem;
    margin-bottom: 0.75rem;
  }

  ol {
    list-style-type: upper-roman;
    li {
      &::marker {
        font-size: 1.5rem;
        line-height: 2.5rem;
        font-weight: bolder;
      }
      span {
        &:first-child {
          font-weight: 700;
        }
      }
    }
    ol {
      list-style-type: decimal;
      li {
        &::marker {
          font-size: 1.5rem;
          line-height: 2rem;
          font-weight: 400;
        }
        span {
          &:first-child {
            font-weight: 400;
          }
        }
      }
      ol {
        list-style-type: lower-alpha;
        ol {
          list-style-type: disc;
          ol {
            list-style-type: circle;
          }
        }
      }
    }
  }
}
