.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #000;
  font-size: 15px;
  padding-top: 15px;

  a,
  span {
    font-size: 15px;
  }

  &-arrow {
    padding: 0 5px;
  }
  &-active {
    color: #666;
    font-size: 15px;
  }
}
