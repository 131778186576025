.footer {
  background-color: $backgroundColor;
  // width: 100%;

  &__wrapper {
    margin: 0 auto;
    max-width: $maxWidthContent * 1px;
    padding: 6.4rem 0 2.4rem;
    @media (max-width: $size-xxl) {
      max-width: calcSizing($maxWidthContent, $maxWidth, 1200) * 1px;
    }
    @media (max-width: $size-xl) {
      max-width: calcSizing($maxWidthContent, $maxWidth, 992) * 1px;
    }
    @media (max-width: $size-lg) {
      max-width: calcSizing($maxWidthContent, $maxWidth, 768) * 1px;
    }
    @media (max-width: $size-md) {
      max-width: unset;
      padding: 1.6rem 1.6rem 5rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    margin-bottom: 66px;
    @media (max-width: $size-xxl) {
      margin-bottom: calcSizing(66, $maxWidth, 1200) * 1px;
    }
    @media (max-width: $size-xl) {
      margin-bottom: calcSizing(66, $maxWidth, 992) * 1px;
    }
    @media (max-width: $size-lg) {
      flex-direction: column;
      margin-bottom: 24px;
    }
  }
  &__uHome {
    width: calc(50% - 1rem);
    display: flex;
    flex-direction: column;
    @media (max-width: $size-lg) {
      width: 100%;
      margin-bottom: 26px;
    }

    > h2 {
      font-weight: 600;
      font-size: 40px;
      line-height: 60px;
      letter-spacing: 1px;
      color: $black2;
      margin-bottom: 12px;

      @media (max-width: $size-xxl) {
        font-size: calcSizing(40, $maxWidth, 1200) * 1px;
        line-height: calcSizing(60, $maxWidth, 1200) * 1px;
        margin-bottom: calcSizing(12, $maxWidth, 1200) * 1px;
      }
      @media (max-width: $size-xl) {
        font-size: calcSizing(40, $maxWidth, 992) * 1px;
        line-height: calcSizing(60, $maxWidth, 992) * 1px;
        margin-bottom: calcSizing(12, $maxWidth, 992) * 1px;
      }
      @media (max-width: $size-lg) {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 12px;
      }
    }

    & > p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 22px;
      color: $gray5;

      @media (max-width: $size-xxl) {
        font-size: calcSizing(16, $maxWidth, 1200) * 1px;
        line-height: calcSizing(24, $maxWidth, 1200) * 1px;
        margin-bottom: calcSizing(22, $maxWidth, 1200) * 1px;
      }
      @media (max-width: $size-xl) {
        font-size: calcSizing(16, $maxWidth, 992) * 1px;
        line-height: calcSizing(24, $maxWidth, 992) * 1px;
        margin-bottom: calcSizing(22, $maxWidth, 992) * 1px;
      }
      @media (max-width: $size-lg) {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 14px;
      }
    }
    & > div {
      display: flex;
      align-items: center;
      & > a > img {
        margin-right: 1.4rem;
        transition: transform 0.8s ease;
        width: 2rem;

        &:hover {
          transform: scale(1.5);
          cursor: pointer;
        }
        @media (max-width: $size-lg) {
          transform: unset;
        }
      }
    }
  }

  &__info {
    width: 25%;
    @media (max-width: $size-lg) {
      width: 100%;
      margin-bottom: 24px;
    }

    > h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: $black2;
      margin-bottom: 16px;

      @media (max-width: $size-xxl) {
        font-size: calcSizing(24, $maxWidth, 1200) * 1px;
        line-height: calcSizing(36, $maxWidth, 1200) * 1px;
        margin-bottom: calcSizing(16, $maxWidth, 1200) * 1px;
      }
      @media (max-width: $size-xl) {
        font-size: calcSizing(24, $maxWidth, 992) * 1px;
        line-height: calcSizing(36, $maxWidth, 992) * 1px;
        margin-bottom: calcSizing(16, $maxWidth, 992) * 1px;
      }
      @media (max-width: $size-lg) {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
      }
    }

    & > p {
      font-size: 16px;
      line-height: 24px;
      color: $gray5;
      margin-bottom: 12px;

      @media (max-width: $size-xxl) {
        font-size: calcSizing(16, $maxWidth, 1200) * 1px;
        line-height: calcSizing(24, $maxWidth, 1200) * 1px;
        margin-bottom: calcSizing(12, $maxWidth, 1200) * 1px;
      }
      @media (max-width: $size-xl) {
        font-size: calcSizing(16, $maxWidth, 992) * 1px;
        line-height: calcSizing(24, $maxWidth, 992) * 1px;
        margin-bottom: calcSizing(12, $maxWidth, 992) * 1px;
      }
      @media (max-width: $size-lg) {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 8px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      a {
        all: inherit;
      }
    }
  }
  &__register {
    width: calc(25% - 1rem);
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    @media (max-width: $size-lg) {
      width: 100%;
      justify-content: flex-start;
      align-items: flex-start;
    }
    img {
      width: 14.9rem;
      height: 5.6rem;
      transition: transform 1s ease;
      &:hover {
        transform: scale(1.2);
      }
    }
  }

  &__reserved {
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    p {
      display: inline;
      font-size: 14px;
      line-height: 18px;
      color: $gray5;

      &:nth-of-type(1) {
        margin-right: 5px;
      }
    }
    &--highlight {
      color: $black2;
      font-weight: 600;
    }
  }
}

// @media screen and (min-width: 1440px) {
//   .footer {
//     &__content {
//       padding: 4.9rem 15rem 9rem;
//       margin: 0;
//       max-width: 144rem;
//     }
//   }
// }

// @media screen and (max-width: 1439px) and (min-width: 1200px) {
//   .footer {
//     &__content {
//       padding: 4.9rem 0 9rem;
//       margin: 0 auto;
//       max-width: 118rem;
//     }
//   }
// }

// @media screen and (max-width: 1199px) and (min-width: 1025px) {
//   .footer {
//     &__content {
//       padding: 4.9rem 0 9rem;
//       margin: 0 auto;
//       max-width: 100rem;
//     }
//   }
// }

// @media screen and (max-width: 1024px) and (min-width: 769px) {
//   .footer {
//     &__content {
//       padding: 4.8rem 11.2rem;
//       margin: 0 auto;
//       display: flex;
//       flex-direction: column;
//     }
//     &__uHome {
//       width: 100%;
//       display: flex;
//       flex-direction: column;
//       margin-right: 0;
//       p {
//         font-size: 1.4rem;
//         line-height: 2rem;
//       }
//       & > div {
//         margin-bottom: 3.9rem;
//       }
//     }
//     &__info {
//       width: 100%;
//       margin-bottom: 3.6rem;
//       margin-right: 0;
//       & > h1 {
//         margin-bottom: 2rem;
//       }
//     }
//     &__register {
//       width: 100%;
//       justify-content: flex-start;
//     }
//   }
// }

// @media screen and (max-width: 768px) and (min-width: 500px) {
//   .footer {
//     &__content {
//       padding: 4.8rem 11.2rem;
//       margin: 0 auto;
//       display: flex;
//       flex-direction: column;
//     }
//     &__uHome {
//       width: 100%;
//       display: flex;
//       flex-direction: column;
//       margin-right: 0;
//       p {
//         font-size: 1.4rem;
//         line-height: 2rem;
//       }
//       & > div {
//         margin-bottom: 3.9rem;
//       }
//     }
//     &__info {
//       width: 100%;
//       margin-bottom: 3.6rem;
//       margin-right: 0;
//       & > h1 {
//         margin-bottom: 2rem;
//       }
//     }
//     &__register {
//       width: 100%;
//       justify-content: flex-start;
//     }
//   }
// }
// @media screen and (max-width: 499px) {
//   .footer {
//     &__content {
//       padding: 4.8rem 1.5rem;
//       margin: 0 auto;
//       display: flex;
//       flex-direction: column;
//     }
//     &__uHome {
//       width: 100%;
//       display: flex;
//       flex-direction: column;
//       margin-right: 0;
//       p {
//         font-size: 1.4rem;
//         line-height: 2rem;
//         & > br {
//           display: none;
//         }
//       }
//       & > div {
//         margin-bottom: 3.9rem;
//       }
//     }
//     &__info {
//       width: 100%;
//       margin-bottom: 3.6rem;
//       margin-right: 0;
//       & > h1 {
//         margin-bottom: 2rem;
//       }
//     }
//     &__register {
//       width: 100%;
//       justify-content: flex-start;
//     }
//   }
// }
