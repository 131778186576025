.cursor-pointer {
  cursor: pointer;
}

iframe#Iframe1 {
  display: none;
}

.image {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
